.products-container {
  display: flex;
  justify-content: center;
  background-color: #d5dbdb;
  border-radius: 0rem 10rem 0rem 10rem;
  min-height: 50rem;
  max-width: 100vw;
  margin-bottom: 1.6rem;
  padding: 2rem 1rem;
  gap: 1rem;
  position: relative;
}

.back-to-home {
  position: absolute;
  left: 2.5rem;
  top: 3.6rem;
  width: 4rem;
  height: 4rem;
  border: none;
  cursor: pointer;
  background: none;
}


.products-container section {
  display: flex;
  max-height: 100%;
  width: 40rem;
  flex-wrap: wrap;
}

.products-container h2 {
  margin-bottom: 3.3rem;
}

.column {
  flex-direction: column;
}

.chemicals-container {
  display: flex;
  justify-content: flex-start;
}

.mini-card-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.mini-card-container div {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
}

.mini-card {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 2px 4px 10px -1px rgba(0, 0, 0, 0.63);
  transition: transform 250ms, opacity 400ms;
}

.mini-card:hover {
  transform: scale(3);
  transition: transform 330ms ease-in-out;
}

.mini-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.others-container {
  justify-content: flex-start;
  width: 35rem;
}

.paints-container {
  margin-top: 8rem;
  text-align: center;
}

.paints-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paints-container span {
  font-family: "Nunito", sans-serif;
  font-size: 2.5rem;
  color: #3a3a3c;
}

@media (max-width: 428px) {
  .products-container {
    flex-direction: column;
  }

  .paints-container {
    margin: 0;
  }
}