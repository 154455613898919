.home {
  margin: 0;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header {
  display: flex;
  justify-content: space-between;
  height: 12rem;
  padding: 5rem;
}

.logo-section {
  display: flex;
  align-items: center;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.logo-section div:first-child {
  flex-direction: column;
}

.logo-section img {
  width: 68px;
  height: 64px;
  margin-right: 2rem;
}

.logo-section h1,
h3 {
  font-weight: 300;
}

.logo-section h1 {
  font-size: 4rem;
}

ul {
  display: flex;
  gap: 2.5rem;
}

ul li {
  list-style-type: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  color: #343447;
}

.address-button {
  border: none;
  background: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  color: #343447;
}

ul li button:hover {
  color: #2d72b3;
}

a {
  text-decoration: none;
  color: black;
}

main h1 {
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 3.3rem;
  font-weight: 600;
}

main section {
  padding: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#produtos {
  background-color: #d5dbdb;
  border-radius: 0rem 10rem 0rem 10rem;
  min-height: 70rem;
  max-width: 100vw;
  justify-content: space-around;
}

#produtos h1:first-child,
h2 {
  color: #343447;
}

#produtos button {
  font-family: "Nunito", sans-serif;
  font-size: 2rem;
  color: #f7ffff;
  border: none;
  border-radius: 1rem;
  margin: 3rem;
  width: 201px;
  height: 49px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #717d7e;
  cursor: pointer;
}

#produtos button:hover {
  background-color: #717d7ec0;
}

#contato em {
  color: #3aec22;
}

.card-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  max-width: 100%;
}

.card {
  box-shadow: 2px 4px 10px -1px rgba(0, 0, 0, 0.63);
  min-width: 20rem;
  height: 45rem;
  display: flex;
  justify-content: center;
  border-radius: 2rem;
}

.card:hover {
  transform: scale(1.01);
  transition: transform 0.01s;
}

.card h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 2.5rem;
  flex-wrap: nowrap;
  color: #343447;
  margin-top: 3rem;
  padding: 0 2.5rem;
  height: 3rem;
  width: 40rem;
  background: linear-gradient(135deg, #fbf117 35%, #e42b8f 54%, #13aee9 100%);
}

.plate,
.paints,
.chemicals,
.paper {
  background-size: contain;
  background-repeat: no-repeat;
}

.plate {
  background-color: #ffffff;
  background-image: url("../../assets/chapas.png");
}

.paints {
  background-image: url("../../assets/tinta.png");
  background-size: cover;
}

.chemicals {
  background-image: url("../../assets/quimicos.png");
  background-size: cover;
  background-position: 50%;
}

.paper {
  background-color: #ffffff;
  background-image: url("../../assets/papel\ vegetal.png");
  background-size: contain;
  background-position: 50%;
}

#onde-me-encontrar {
  background-color: #717d7e;
  border-radius: 0rem 10rem 0rem 10rem;
  min-height: 70rem;
  max-width: 100vw;
}

#produtos h1,
#onde-me-encontrar h1 {
  color: #f7ffff;
  margin-bottom: 1rem;
}

.maps {
  display: flex;
  justify-content: center;
  gap: 15rem;
  max-width: 100%;
}

.simple-map,
.street-view {
  width: 650px;
  height: 45rem;
  margin-top: 5rem;
  border: 0;
  border-radius: 1rem;
  box-shadow: 2px 4px 10px -1px rgba(0, 0, 0, 0.63);
}

#quem-somos {
  background-color: #d5dbdb;
  border-radius: 0rem 10rem 0rem 3rem;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 37rem;
}

#quem-somos h1 {
  color: #343447;
  margin-bottom: 4rem;
}

#quem-somos div:first-child {
  width: 70%;
}

#quem-somos p {
  font-family: "Nunito", sans-serif;
  font-size: 2.5rem;
  color: #3a3a3c;
  display: flex;
  justify-content: center;
  text-align: center;
}

footer {
  display: flex;
  justify-content: space-evenly;
  min-height: 30rem;
  background-color: #717d7e;
  border-radius: 0rem 10rem 0rem 10rem;
}

footer div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

footer h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 2.3rem;
  text-align: center;
  color: #f7ffff;
  margin: 4rem 0rem;
}

footer span,
a {
  font-family: "Nunito", sans-serif;
  font-size: 2rem;
  color: #f7ffff;
}

footer strong {
  font-size: 1.9rem;
  color: #f7ffff;
}

.whatsapp-link {
  position: fixed;
  overflow: hidden;
  bottom: 5rem;
  right: 4.5rem;
  color: green;
}

.whatsapp-link img {
  width: 100px;
  height: 90px;
}

#mobile-logo {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .card {
    width: 29rem;
    height: 45rem;
  }

  .card h3 {
    width: 100%;
    font-size: 2.1rem;
    background: linear-gradient(135deg, #fbf117 35%, #e42b8f 54%, #13aee9 100%);
  }

  .plate {
    background-size: cover;
    background-position: 50%;
  }

  .maps {
    gap: 3rem;
    width: 100%;
  }

  .simple-map,
  .street-view {
    width: 62.5rem;
  }
}

@media (max-width: 428px) {
  .home {
    padding: 0;
    overflow-x: hidden;
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
    height: 15rem;
    max-width: 428px;
  }

  .logo-section {
    margin: 1.8rem 0;
    height: 80%;
  }

  .logo-section h1 {
    margin-left: 0.4rem;
    font-size: 3rem;
  }

  .logo-section h3 {
    font-size: 1.7rem;
  }

  .menu {
    display: flex;
    justify-content: center;
  }

  .menu ul {
    gap: 3rem;
    margin-right: 1.8rem;
  }

  ul li {
    max-width: 4.5rem;
    font-size: 1.4rem;
    letter-spacing: 0.03rem;
    margin-left: 0.3rem;
  }

  .address-button {
    font-size: 1.4rem;
  }

  .quem-somos-li {
    margin: 0 -2rem;
    margin-left: 0.1rem;
    min-width: 100px;
  }

  #produtos {
    border-radius: 0rem 4.5rem 0rem 10rem;
  }

  #produtos div {
    flex-direction: column;
  }

  .card {
    min-width: 37rem;
    border-radius: 2rem;
  }

  .card h3 {
    margin-bottom: 30rem;
  }

  #produtos h2 {
    text-align: center;
    padding: 3rem 1rem;
    font-size: 2.3rem;
  }

  #onde-me-encontrar {
    display: flex;
    width: 100%;
    border-radius: 0rem 7rem 0rem 3rem;
  }

  #onde-me-encontrar h1 {
    margin-top: 1rem;
    font-size: 3.26rem;
  }

  .maps {
    flex-direction: column;
    gap: 0;
  }

  .simple-map,
  .street-view {
    padding: 0 1rem;
    max-height: 290px;
    width: 39rem;
    border-radius: 3rem;
    box-shadow: none;
  }

  #quem-somos {
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60rem;
  }

  #quem-somos div:first-child {
    width: 100%;
  }

  #quem-somos h1 {
    font-size: 3.4rem;
    margin: 4rem 0;
  }

  #quem-somos p {
    padding: 0 2rem;
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.1rem;
    width: 100%;
  }

  #contato {
    flex-direction: column;
    min-height: 70rem;
  }

  #contato h3 {
    margin-bottom: 8rem;
  }

  #pc-logo {
    display: none;
  }

  #mobile-logo {
    display: block;
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 370px) {
  #onde-me-encontrar h1 {
    font-size: 3rem;
  }
}
